<template>
  <div>
    <a-form
      :form="form"
      :labelCol="labelCol"
      :wrapperCol="wrapperCol">
      <a-form-item
        hidden
        label="tagsId"
      >
        <a-input
          v-decorator="['tagsId',{ initialValue:'0' }]"
          name="tagsId"></a-input>
      </a-form-item>
      <a-form-item
        label="标签名称"
      >
        <a-input
          v-decorator="[
            'name',
            {rules: [{ required: true, message: '请输入标签名称' }]}
          ]"
          name="name"
          placeholder="请输入标签名称"/>
      </a-form-item>
      <a-form-item
        :required="false"
        label="状态"
      >
        <a-radio-group v-decorator="['status', { initialValue: 1 }]" name="status">
          <a-radio :value="1">启用</a-radio>
          <a-radio :value="2">禁用</a-radio>
        </a-radio-group>
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
export default {
  data () {
    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      form: this.$form.createForm(this)
    }
  }
}
</script>
